import React from 'react';

import PageWrap from 'components/PageWrap';
import { Section } from 'components/Section';
import { PagePicTitle } from 'components/PagePicTitle';

import { FeaturePoint } from 'components/FeaturePoint';
import CallsToAction from 'components/CallsToAction';
import { Separator } from 'components/Separator';
import IndyLink from 'components/IndyLink';
import { SEO } from 'components/Helmets';

import DesignIcon from 'media/shared/modIcons/Design.inline.svg';

import addItemImg from 'media/Screenshots/Design/AddItem.png';
import itemDetailsImg from 'media/Screenshots/Design/ItemDetails.png';
import itemListImg from 'media/Screenshots/Design/ItemList.png';
import projOverviewImg from 'media/Screenshots/Design/ProjectOverview.png';

export default function ModDesign({data}){
    const CTATypes = ['SignUp', 'Demo'];
    const pagePath = "/modules/design";

    return (
        <PageWrap noSEO={true} path={pagePath}>
            <SEO
                title="The Design Module - Mashoom"
                description="A new way of storing, sharing, organizing and controlling your engineering CAD data and associated information."
                previewImgKey="design"
                path={pagePath}
            />
            <PagePicTitle
                imageCmp={DesignIcon}
                imageAlt="Design module icon">
                        <h1>The Design Module</h1>
                        <p>This module has been developed over many years in tandem with many engineering teams in many different sectors. It's a new way of storing, sharing, organizing and controlling your engineering CAD data and associated information.</p>
            </PagePicTitle>
            <FeaturePoint
                title="We see a problem"
                image={itemListImg}
                pageInd="1"
            >
                <p>Engineering design control and centralized storage is not a new concept, in-fact this is part of the problem in our eyes.</p>
                <p className="paraSpaceTop">The current thinking is that to successfully store and manage CAD data and drawings you must spend thousands on deployed software that requires constant maintenance and only partly addresses what you set out to solve.</p>
                <p className="paraSpaceTop">Most likely, you also get tied into one software family with potentially huge consequences as the times change. That is without getting started on the costs involved when these systems need updating, or worse, go wrong.</p>
            </FeaturePoint>
            <Separator />
            <FeaturePoint
                title="The solution is simple"
                image={addItemImg}
                pageInd="2"
            >
                <p>Mashoom's Design module brings an end to the old way of doing things. The concept is simple, we provide all the tools you need to store and control designs via our online platform.</p>
                <p className="paraSpaceTop">Our clever part numbering engine can interpret your part numbering scheme to automatically generate, update and interpret part numbers. We know part numbers are well recognized in the industry, we use them because they work and most people are already familiar with them.</p>
                <p className="paraSpaceTop">When new designs are uploaded they must be named correctly, with a their part number and name. We know from experience this starts to organize everyone, even when working offline.</p>
            </FeaturePoint>
            <Separator />
            <FeaturePoint
                title="Keep your team independent and agile"
                image={itemDetailsImg}
                pageInd="3"
            >
                <p>We never read your CAD data, our system doesn't rely on any CAD providers plugins etc; we are completely independent from them. Want to use multiple CAD providers on one account? No problem!</p>
                <p className="paraSpaceTop">The Design module supports CAD assemblies, by uploading an "information" file alongside the CAD assembly. From these files we can provide a one-click download of an assembly and reporting of which parts are up to date in the assembly.</p>
            </FeaturePoint>
            <Separator />
            <FeaturePoint
                title="Top down and bottom up analysis"
                image={projOverviewImg}
                pageInd="4"
            >
                <p>We store a complete history of everything that has happened to a design. This includes when it happened, the person who did it and a full version history of data.</p>
                <p className="paraSpaceTop">A simple interface allows you to quickly drill into who and what actions were taken on the design. For instance, filter only for editing history so you can see how much time was spent on the design, or find out when it was authorized, and by who.</p>
                <p className="paraSpaceTop">Design data also feeds into our free <IndyLink adr="/addons">reporting add-on</IndyLink> and a project overview page. This allows a top down look at a projects progress in real time, and a historic overview of activity across the design module.</p>
            </FeaturePoint>
            <Section id="FeatureCTA">
                <div id="FeatureCTACont">
                    <h2>Like what your hear?</h2>
                    <CallsToAction types={CTATypes}></CallsToAction>
                </div>
            </Section>
        </PageWrap>
    );
}